html,
body,
#root {
  height: 100vh;
  width: 100vw;
  font-family: "Open Sans", sans-serif;
}
.ellipse {
  white-space: pre-wrap;
  text-overflow: ellipsis;
  width: 180px;
  display: inline-block;
  line-height: 18px;
}
.text-16 {
  font-size: 16px;
}

.swiper-container {
  width: 100%;
  padding-top: 0px !important;

  padding-left: 20px !important;
  padding-right: 20px !important;

  margin-bottom: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination {
  display: none;
}
.swiper-slide {
  width: 90%;
}

.card {
  overflow: hidden;
}
.transparent {
  background-color: transparent;
}
body {
  background: #efefef;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  background-color: transparent;
  border-bottom: 1px solid #075ef5;
  border-radius: 0px;
}
.nav-pills .nav-link {
  color: #6c757d;
}
.covidSumm {
  flex: 1 1 0%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.dropdownStyle {
  background-color: #ffffff5e;
  border: none;
  border-radius: 8px;
}
.text-small {
  font-size: 10px !important;
}
.text-medium {
  font-size: 18px !important;
}
.text-large {
  font-size: 30px !important;
}
a {
  text-decoration: none;
}
.miniapp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: #41464b;
  font-size: 10px;
  line-height: 25px;
  font-weight: bold;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar {
  height: 50px;
  background: transparent !important;
}
.navbar-light .navbar-toggler {
  border: none;
}
.app-container {
  height: calc(100vh - 130px);
  overflow: scroll;
}
.widgets-container {
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}
.title-heading {
  font-family: "Righteous", cursive;
}
.App {
  text-align: center;
}
.shadow1 {
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
}
.card {
  border: none;
  border-radius: 12px;
}
.header-link {
  color: #41464b;
}
h3 {
  font-size: 0.9rem;
}
h4 {
  font-size: 0.85rem;
}
h5 {
  font-size: 0.7rem;
}
h6 {
  font-size: 0.6rem;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.gradient-card {
  background: #4e54c8; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #8f94fb,
    #4e54c8
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #8f94fb,
    #4e54c8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.menu {
  display: flex;
  flex-grow: 1;
  height: 70px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(52, 46, 173, 0.25),
    0 15px 30px 0 rgba(52, 46, 173, 0.1);
}

.menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-decoration: none;
  color: #41464b;
}
.menu-item:focus,
.menu-item:hover {
  outline: none;
}
.menu-item:focus .material-icons,
.menu-item:hover .material-icons {
  font-family: "Material Icons";
  color: #342ead;
}
.menu-item:focus .menu-item-label,
.menu-item:hover .menu-item-label {
  color: #342ead;
}

.material-icons {
  display: block;
  margin-bottom: 4px;
  font-size: 26px;
  transition: 0.25s ease;
}

.menu-item-label {
  display: block;
  font-size: 13px;
  color: #c2c0e6;
  transition: 0.25s ease;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
